@import 'scss/variables';

body {
  .MmTrend {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .Left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .Logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: left;
        gap: 16px;

        .Image {
          width: 94px;
        }

        .Title {
          width: 100%;

          @media (min-width: $sm) {
            width: 362px;
          }
        }

        @media (min-width: $sm) {
          gap: 24px;
        }

        @media (min-width: $md) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: left;
          gap: 32px;
        }
      }

      .Link {
        width: 100%;
        max-width: 198px;
        height: 32px;
        width: 102px;
        font-size: 14px;
        line-height: 16px;

        @media (min-width: $md) {
          height: 48px;
          width: 100%;
          font-size: 18px;
          line-height: 24px;
        }
      }

      @media (min-width: $md) {
        gap: 32px;
        // min-width: 362px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }

    .Right {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .Title {
        color: $color-black4;
      }

      .Cards {
        max-width: 100%;
        padding-bottom: 40px;

        :global(.swiper-pagination) {
          display: flex;
          justify-content: center;
          align-items: center;
          bottom: 0;

          :global(.swiper-pagination-bullet) {
            margin: 0 5px;
            width: 12px;
            height: 12px;
            background-color: rgba($color-black, 0.5);

            &:global(.swiper-pagination-bullet-active) {
              background-color: $color-black;
            }
          }
        }

        @media (min-width: $sm) {
          max-width: 890px;
        }
      }
    }

    @media (min-width: $sm) {
      flex-direction: column;
      gap: 26px;
    }

    @media (min-width: $md) {
      flex-direction: row;
      gap: 48px;
    }
  }
}
