@import 'scss/variables';

$desktopWidth: 580px;
$tabletWidth: 441px;

body {
  .FullQuiz {
    .Steps {
      &.Home {
        background: $color-black;
        background-image: url('https://assets.moneymade.io/images/tranding/bgMobile.png');
        background-size: auto 211px;
        background-repeat: no-repeat;
        background-position: right bottom;

        &.StepsAll {
          min-height: 558px;
        }

        &.StepsRadio {
          min-height: 728px;
        }

        @media (min-width: $sm) {
          height: 650px;
          background-image: url('https://assets.moneymade.io/images/tranding/bgTablet.png');
          background-size: auto 372px;
        }

        @media (min-width: $md) {
          height: 650px;
          background-image: url('https://assets.moneymade.io/images/tranding/bgDesktop.png');
          background-size: contain;
          background-size: auto 557px;
        }
      }

      &.Discovery {
        margin-top: 70px;
        background: $color-teal;
        background-image: url('https://assets.moneymade.io/images/tranding/bgDiscoveryMobile.png');
        background-size: auto 126px;
        background-repeat: no-repeat;
        background-position: right bottom;

        &.StepsAll {
          min-height: 350px;

          @media (min-width: $sm) {
            min-height: 236px;
          }
        }

        &.StepsRadio {
          min-height: 558px;

          @media (min-width: $sm) {
            min-height: 397px;
          }
        }

        @media (min-width: $xs) {
          margin-top: 0;
        }

        @media (min-width: $sm) {
          min-height: 236px;
          background-image: url('https://assets.moneymade.io/images/tranding/bgDiscoveryTablet.png');
          background-size: auto 170px;
        }

        @media (min-width: $md) {
          min-height: 236px;
          background-image: url('https://assets.moneymade.io/images/tranding/bgDiscoveryDesktop.png');
          background-size: contain;
          background-size: auto 230px;
        }
      }

      .Container {
        max-width: 1230px;
        width: 100%;
        margin: 0 auto;

        .Title {
          margin: 0 20px;

          :global(.mu-h1) {
            display: flex;
            flex-wrap: wrap;
            column-gap: 10px;
          }

          span:nth-child(1),
          span:nth-child(3) {
            color: $color-white;
          }

          &.Home {
            padding-top: 76px;

            span:nth-child(2) {
              color: $color-red5;
            }

            @media (min-width: $sm) {
              padding-top: 120px;
              max-width: $tabletWidth;
            }

            @media (min-width: $md) {
              padding-top: 160px;
              max-width: $desktopWidth;
            }
          }

          &.Discovery {
            padding-top: 28px;

            span:nth-child(2) {
              color: $color-white;
            }

            @media (min-width: $sm) {
              padding-top: 48px;
            }

            @media (min-width: $md) {
              padding-top: 48px;
            }
          }
        }

        .SubTitle {
          margin: 8px 20px 28px 20px;

          &.Home {
            color: $color-black7;

            @media (min-width: $sm) {
              max-width: 491px;
              margin: 8px 20px 36px 20px;
            }

            @media (min-width: $md) {
              max-width: $tabletWidth;
            }
          }

          &.Discovery {
            color: $color-white;

            @media (min-width: $sm) {
              margin: 8px 20px 24px 20px;
            }
          }
        }

        .BtnQuiz {
          margin: 0 20px;
        }

        .Desc {
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 24px;
          color: $color-white;

          @media (min-width: $sm) {
            padding-left: 15px;
            padding-right: 15px;
          }

          &.Home {
            padding-top: 116px;

            @media (min-width: $sm) {
              padding-top: 90px;
              max-width: $tabletWidth;
            }

            @media (min-width: $md) {
              padding-top: 120px;
              max-width: $desktopWidth;
            }
          }

          &.Discovery {
            padding-top: 28px;

            @media (min-width: $sm) {
              padding-top: 44px;
              max-width: 560px;
            }

            @media (min-width: $md) {
              padding-top: 48px;
              max-width: 972px;
            }
          }
        }

        .BtnsLayout {
          min-height: 50px;

          .Btns {
            position: relative;
            flex-direction: row;
            gap: 16px;
            scrollbar-width: none;
            overflow-x: auto;
            padding-left: 20px;

            &::-webkit-scrollbar {
              display: none;
              width: 0;
            }

            &.Show {
              display: flex;
              transition: display 0.5s;
            }

            &.Hide {
              display: none;
            }

            .Btn {
              &:hover {
                background-color: $color-red5;
                border-color: $color-red5;
                color: $color-white;
              }

              @media (min-width: $sm) {
                width: fit-content;
              }
            }

            .Right {
              &.Home {
                &::before {
                  background: linear-gradient(269.16deg, $color-black -29.97%, rgba(0, 0, 0, 0) 57.22%);
                }

                @media (min-width: $sm) {
                  display: none;
                }
              }

              &.Discovery {
                &::before {
                  background: linear-gradient(269.16deg, $color-teal -29.97%, rgba(20, 174, 165, 0) 57.22%);
                }
              }

              &::before {
                height: 50px;
                width: 50px;
                content: '';
                pointer-events: none;
              }

              position: sticky;
              display: flex;
              width: 124px;
              height: 50px;
              right: 0;
            }

            &.Home {
              @media (min-width: $sm) {
                padding-left: 0;
                flex-direction: column;
              }
            }
          }

          &.Home {
            @media (min-width: $sm) {
              padding-left: 16px;
              flex-direction: column;
            }
          }

          &.Discovery {
            @media (min-width: $sm) {
              width: 70%;
            }

            @media (min-width: $md) {
              width: 80%;
            }

            @media (min-width: $lg) {
              width: 90%;
            }
          }
        }

        .RadiosDesc {
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 24px;
          color: $color-white;

          &.Home {
            padding-top: 116px;

            @media (min-width: $sm) {
              padding-top: 90px;
              max-width: $tabletWidth;
            }

            @media (min-width: $md) {
              padding-top: 120px;
              max-width: $desktopWidth;
            }
          }

          &.Discovery {
            padding-top: 28px;

            @media (min-width: $sm) {
              padding-top: 34px;
              max-width: 560px;
            }

            @media (min-width: $md) {
              padding-top: 48px;
              max-width: 972px;
            }
          }
        }

        .Radios {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 0 20px;

          .RadioContainer {
            padding: 12px 16px;
            background: rgba(118, 118, 122, 0.2);
            border-radius: 8px;

            :global(.mu-label) {
              color: $color-white;
            }
          }

          .Btn {
            margin-top: 24px;
            width: 72px;

            &.Home {
              margin-left: auto;
              margin-right: auto;
            }

            @media (min-width: $sm) {
              margin-left: 0;
              margin-right: 0;
            }
          }

          &.Home {
            @media (min-width: $sm) {
              max-width: $tabletWidth;
            }

            @media (min-width: $md) {
              max-width: $desktopWidth;
            }
          }

          &.Discovery {
            @media (min-width: $sm) {
              max-width: 560px;
            }

            @media (min-width: $md) {
              max-width: 972px;
            }
          }
        }

        .Bottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          margin-top: 24px;
          padding-left: 15px;
          padding-right: 15px;

          .Dots {
            display: flex;
            flex-direction: row;
            gap: 3px;

            .Dot {
              width: 6px;
              height: 6px;
              border-radius: 50%;
            }

            .Active {
              background: $color-white;
            }

            .NotActive {
              background: $color-white;
              opacity: 0.2;
            }

            .NotActiveSecond {
              background: $color-white;
              opacity: 0.4;
            }
          }

          .Text {
            font-style: italic;

            &.Home {
              color: $color-black5;
            }

            &.Discovery {
              color: $color-black10;
            }
          }
        }

        .Loading {
          display: flex;
          padding-top: 77px;
          padding-left: 20px;
          padding-right: 20px;
          flex-direction: column;
          align-items: center;
          gap: 4px;

          &.Home {
            color: $color-turquoise;

            :global(.mu-p2) {
              color: $color-turquoise;
            }
          }

          &.Discovery {
            color: $color-white;

            :global(.mu-p2) {
              color: $color-white;
            }
          }

          @media (min-width: $sm) {
            padding-top: 120px;
            padding-left: 15px;
            padding-right: 15px;
            flex-direction: row;
          }

          @media (min-width: $md) {
            padding-top: 160px;
          }
        }
      }
    }

    .Result {
      &.Home {
        background: $color-black;
      }

      &.Discovery {
        background: $color-teal;
      }

      .Container {
        position: relative;
        display: flex;
        padding: 24px;
        align-items: center;
        flex-direction: column;

        .Img {
          margin-bottom: 16px;
          padding: 10px;
          border-radius: 50%;

          &.Home {
            color: $color-black;
            background: $color-turquoise;
          }

          &.Discovery {
            color: $color-teal;
            background: $color-white;
          }
        }

        :global(.mu-h4) {
          margin-bottom: 24px;
          max-width: 335px;
        }

        :global(.mu-p2) {
          margin-bottom: 13px;
        }

        :global(.mu-p2),
        :global(.mu-h4) {
          color: $color-white;
          text-align: center;
        }

        &.Home {
          :global(.mu-container) {
            width: 327px;

            :global(.mu-input) {
              background-color: $color-black;
              color: $color-white;
            }
          }
        }

        &.Discovery {
          :global(.mu-container) {
            width: 327px;

            :global(.mu-input) {
              background-color: $color-teal;
              color: $color-white;
            }
          }
        }

        .Btn {
          margin-top: 24px;
        }

        @media (min-width: $sm) {
          padding: 72px;
        }
      }
    }
  }
}
