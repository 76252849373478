@import 'scss/variables';

body {
  .PlatformCard {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 20px;
    width: 100%;
    border-radius: 12px;
    background-color: $color-white;
    transition: box-shadow $trans;

    &:hover {
      box-shadow: $modal-shadow;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .Header {
      display: flex;
      flex-direction: row;
      gap: 18px;
      margin-bottom: 13px;

      img {
        object-fit: cover;
        object-position: center;
        border-radius: 12px;
        margin-bottom: 10px;
      }

      .Name {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
    }

    .Description {
      margin-top: 10px;
      margin-bottom: 21px;
      min-height: 72px;
    }

    .Info {
      .TopPart {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 18px;
        margin-bottom: 25px;

        @media (min-width: $md) {
          margin-bottom: 24px;
          justify-content: space-between;
        }
      }
    }

    @media (min-width: $sm) {
      width: 100%;
    }
    @media (min-width: $md) {
      width: 100%;
      max-width: 333px;
    }
  }
}
